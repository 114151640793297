import React, { useState } from "react";
import { auth } from "./firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('userUID', auth.currentUser.uid);

      // Success toast notification
      toast.success("Logged in successfully!", {
        position: 'top-right',
        autoClose: 1500,
      });

      // Navigate after a short delay to ensure the toast is displayed
      setTimeout(() => {
        navigate("/dashboard", { state: { userUID: auth.currentUser.uid } });
      }, 1000);
    } catch (error) {
      // Error toast notification
      toast.error("Invalid credentials: " + error.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login-container" onKeyPress={handleKeyPress}>
      <div className="left-panel">
        <img src="/images/mainlogo.png" alt="Sample" />
      </div>
      <div className="right-panel">
        <h2>Login</h2>
        <div className="input-wrapper">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email-input"
            required
          />
          <label htmlFor="email-input" className={email ? "active" : ""}>
            Email
          </label>
        </div>
        <div className="input-wrapper">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password-input"
            required
          />
          <label htmlFor="password-input" className={password ? "active" : ""}>
            Password
          </label>
        </div>
        <button className="login_btn" onClick={handleLogin}>Login</button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
