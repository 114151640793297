// App.js
import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import { auth } from "./firebase-config";
import Course from "./Courses";

const PrivateRoute = ({ element }) => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    // You can show a loading spinner or another loading indicator here
    return null;
  }

  return user ? element : <Navigate to="/" />;
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
      <Route path="/courses/:courseName" element={<PrivateRoute element={<Course />} />} />
    </Routes>
  );
};

export default App;
