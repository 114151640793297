// your-firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';  // Make sure to import getFirestore

const firebaseConfig = {
  apiKey: "AIzaSyBm1q18al8hXRy6fwO6VVRZJgws9D2AHqs",
  authDomain: "venky-82b70.firebaseapp.com",
  projectId: "venky-82b70",
  storageBucket: "venky-82b70.appspot.com",
  messagingSenderId: "837337353562",
  appId: "1:837337353562:web:a487376344c6218df87763",
  measurementId: "G-E47WW8M2X4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);  // Make sure to get the Firestore instance

// ... (rest of your code)
const handleAuthStateChange = (user) => {
  if (user) {
    console.log('User is logged in:', user);
  } else {
    console.log('User is logged out');

    // If user logs out, clear localStorage
    localStorage.removeItem('userUID');
  }
};


// Set up authentication state observer
onAuthStateChanged(auth, handleAuthStateChange);

export { app, auth, firestore };
