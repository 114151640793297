
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth,firestore } from "./firebase-config";
import { doc, getDoc } from "firebase/firestore";


const Dashboard = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [userCourses, setUserCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email);
      const fetchUserCourses = async () => {
        try {
          const userCoursesDocRef = doc(firestore, "courses", user.uid);
          const userCoursesSnapshot = await getDoc(userCoursesDocRef);
          if (userCoursesSnapshot.exists()) {
            const userData = userCoursesSnapshot.data();
            const enrolledCourses = Object.keys(userData).filter(
              (key) => userData[key] === true
            );
            setUserCourses(enrolledCourses);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user courses:", error.message);
          setLoading(false);
        }
      };
      fetchUserCourses();
    }
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  const handleCourseClick = (courseName) => {
    navigate(`/courses/${courseName}`);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          borderBottom:'2px solid #ccc',
          height: "4rem",
          padding:'20px'
        }}
      >
        <img style={{height:'50px'}} src="/images/mainlogo.png"></img>
     
        <div style={{ marginLeft: "auto", marginRight: "20px" ,display:'flex',justifyContent:'center',alignItems:'center'}}>
        <p style={{color:'blue',padding:'15px'}}> {userEmail}</p>
          <button
            onClick={handleLogout}
            style={{
              backgroundColor: "none",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
             
         <img style={{height:'30px',width:'30px'}} src="https://img.icons8.com/?size=100&id=24337&format=png&color=000000"/>
          </button>
        </div>
      </div>
    <div className="welcome-banner mt-5">
<div className="welcome-text">
  <h4 style={{fontSize:'1.49rem',fontWeight:'300'}}>Welcome Back, <span> {userEmail}</span> </h4>
  <p>"You're on your way to achieving your goals. Keep learning!"</p>
</div>
<div className="welcome-image ">
  <img className="img-fluid d-none  d-sm-block"  src="images/welcome-image.png" alt="" />
</div>
    </div>

      
      <div className="course-container container" style={{ padding: "20px 20px" }}>
       
        <h3>Courses Enrolled In</h3>
       
        {loading ? (
          <p>Loading user courses...</p>
        ) : (
          <div className="course-card-container mt-5">
            {userCourses.map((course) => (
              <Link
                key={course}
                to={`/courses/${course}`}
                style={{ textDecoration: "none" }}
              >
                <div className="course-card">
                  <div className="course-title">
                    <h2 style={{fontWeight:'700'}}>{course}</h2>
                  </div>
                  <div className="course-text">
                    <p >to achieving your goals. Keep learning!</p>
                    <button >Start Learning</button>
                  </div>
                

              
                </div>
                
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
