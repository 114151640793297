
// // export default CoursePage;

// import React, { useEffect, useState } from "react";
// import { useParams, useNavigate,Link  } from "react-router-dom";
// import { auth } from "./firebase-config";



// const CoursePage = () => {
//   const { courseName } = useParams();
//   const [videos, setVideos] = useState([]);
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchVideos = async () => {
//       const user = auth.currentUser;

//       if (!user) {
//         navigate("/", { replace: true }); // Redirect to login page if user not logged in
//         return;
//       }

//       try {
//         // Fetch videos for the course using the courseName parameter
//         const response = await fetch(
//           `https://vegrade-classes.s3.ap-south-1.amazonaws.com/${courseName}/video.json`,
//           {
//             method: "GET",
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to fetch");
//         }

//         const data = await response.json();
//         setVideos(data);
//       } catch (error) {
//         console.error(`Error fetching videos for ${courseName}:`, error.message);
//       }
//     };

//     fetchVideos();
//   }, [courseName, navigate]);

//   const handleVideoClick = (video) => {
//     setSelectedVideo(video);
//   };

//   const handleLogout = async () => {
//     try {
//       await auth.signOut();
//       navigate("/", { replace: true });
//     } catch (error) {
//       console.error("Error logging out:", error.message);
//     }
//   };

//   return (
//     <div style={{ maxWidth: "100%", overflow: "hidden" 
    
//     }}>
//        <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           color: "white",
//           borderBottom:'2px solid #ccc',
//           height: "4rem",
//           padding:'20px'
//         }}
//       >
//         <img style={{height:'50px'}} src="/images/mainlogo.png"></img>
     
//         <div style={{ marginLeft: "auto", marginRight: "20px" ,display:'flex',justifyContent:'center',alignItems:'center'}}>
//         {/* <p style={{color:'blue',padding:'15px'}}> {userEmail}</p> */}
//           <button
//             onClick={handleLogout}
//             style={{
//               backgroundColor: "none",
//               color: "white",
//               padding: "8px 16px",
//               border: "none",
//               borderRadius: "4px",
//               cursor: "pointer",
//             }}
//           >
             
//          <img style={{height:'30px',width:'30px'}} src="https://img.icons8.com/?size=100&id=24337&format=png&color=000000"/>
//           </button>
//         </div>
//       </div>
//       <div className="return mt-4" style={{marginLeft:'5rem'}}>
//       <Link to="/dashboard">
//     <img style={{ height: '30px', cursor: 'pointer' }} src="/images/home-logo.png" alt="Home Logo" />
//   </Link>
//       </div>



//       <div className="mt-5" style={{ display: "flex" }}>
//         <div style={{ flex: "1 1 20%", borderRight: "1px solid #ccc", paddingRight: "10px", overflowY: "auto" }}>
//           <h4>Course Modules</h4>
//           <ul style={{ listStyleType: "none", padding:'20px', paddingLeft: "20px" }}>
//             {videos.map((video, index) => (
//               <li
//                 key={index}
//                 onClick={() => handleVideoClick(video)}
//                 style={{
//                   cursor: "pointer",
//                   marginBottom: "10px",
//                   borderBottom:'2px solid #ccc',
//                   color: selectedVideo && selectedVideo.title === video.title ? "blue" : "inherit",
//                 }}
//               >
//           {video.title}
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div style={{ flex: "2 1 70%", paddingLeft: "10px" }}>
//           {selectedVideo ? (
//             <div style={{ width: "100%", border: "1px solid #ccc", padding: "10px", height: "80vh" }}>
//               <video
//                 controls
//                 controlsList="nodownload"
//                 disablePictureInPicture
//                 src={selectedVideo.url}
//                 type="video/mp4"
//                 style={{ width: "100%", height: "100%" }}
//               />
//             </div>
//           ) : (
//             <p>No video selected</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CoursePage;



// testing

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { auth } from "./firebase-config";

const CoursePage = () => {
  const { courseName } = useParams();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      const user = auth.currentUser;

      if (!user) {
        navigate("/", { replace: true }); // Redirect to login page if user not logged in
        return;
      }

      try {
        // Fetch videos for the course using the courseName parameter
        const response = await fetch(
          `https://vegrade-classes.s3.ap-south-1.amazonaws.com/${courseName}/video.json`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch");
        }

        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error(`Error fetching videos for ${courseName}:`, error.message);
      }
    };

    fetchVideos();
  }, [courseName, navigate]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <div className="course-page-container">
      <div className="course-header">
        <img src="/images/mainlogo.png" alt="Logo" />
        <div className="logout-button-container">
          <button onClick={handleLogout} className="logout-button">
            <img src="https://img.icons8.com/?size=100&id=24337&format=png&color=000000" alt="Logout" />
          </button>
        </div>
      </div>
      <div className="return mt-4">
        <Link to="/dashboard">
          <img className="home-logo" src="/images/home-logo.png" alt="Home Logo" />
        </Link>
      </div>

      <div className="course-content">
        {/* Video Section */}
        <div className="course-video">
          {selectedVideo ? (
            <div className="video-container">
              <video
                controls
                controlsList="nodownload"
                disablePictureInPicture
                src={selectedVideo.url}
                
                type="video/mp4"
              />
            </div>
          ) : (
            <p>Please Select The Course Module</p>
          )}
        </div>

        {/* Modules Section */}
        <div className="course-modules " style={{borderLeft:'2px solid #ccc',}}>
          <h4>Course Modules</h4>
          <ul>
            {videos.map((video, index) => (
              <li
                key={index}
                onClick={() => handleVideoClick(video)}
                className={`module-item ${selectedVideo && selectedVideo.title === video.title ? "selected" : ""}`}
              >
                {video.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;

